import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../hooks';
import { ZAP_PROGRAM_DEFAULT_IMAGE_SVG, ZAP_PROGRAM_GRADIENT_BACKDROP, ZAP_PROGRAM_MAIN_TITLE, ZAP_PROGRAM_PREVIEW, ZAP_PROGRAM_SECOND_TITLE, ZAP_PROGRAM_TITLES, ZAP_PROGRAM_WRAPPER } from './styles';
import { withBreakPoints } from '../../hoc';
import { USER_CLICK, USER_KEYBOARD, USER_REQUEST_MEDIA_LOAD } from '../../../types';
import { PLAYLIST_NAME, PROGRAM_CLICKED_EVENTS, ZAPPING_NAME } from '../wrappers/Zap/constants';
import { ACTIVE_ITEM_BACKGROUND } from '../../theme/colors';

function Program({ metadata, small, active, activeProgramMetadata = {}, currentTab, programNumber, channels }) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  const PROGRAM_VISUAL_STYLE = {
    ...ZAP_PROGRAM_GRADIENT_BACKDROP,
    backgroundImage: metadata?.image
      ? `${ZAP_PROGRAM_GRADIENT_BACKDROP.backgroundImage},url(${metadata.image})`
      : `${ZAP_PROGRAM_GRADIENT_BACKDROP.backgroundImage},url(${ZAP_PROGRAM_DEFAULT_IMAGE_SVG})`
  };

  const handleClick = useCallback((fromKeyboard) => {
    const source = fromKeyboard ? USER_KEYBOARD : USER_CLICK;

    player.userEvents$.next({
      action: PROGRAM_CLICKED_EVENTS[currentTab],
      value: {
        id: metadata.id,
        previousChannel: activeProgramMetadata.name,
        currentChannel: metadata.name,
        ...metadata
      },
      source
    });

    if ([ZAPPING_NAME, PLAYLIST_NAME].includes(currentTab)) {
      const { id: src, name, options: config } = metadata;
      let tracking;
      switch (currentTab) {
        case ZAPPING_NAME:
          tracking = {
            diffusion: { mode: 'live_from_zapping' },
            tracking: {
              pageProvenance: '',
              zoneProvenance: 'zapette_player',
              positionVignette: '',
              playProvenance: 'zapette'
            }
          };
          break;
        case PLAYLIST_NAME:
          tracking = {
            /* position is the human readable version of the index, hence the +1 */
            diffusion: { mode: 'tunnel', position: programNumber + 1, length: channels.length },
            tracking: {
              pageProvenance: '',
              zoneProvenance: 'playlist_player',
              positionVignette: '',
              playProvenance: 'playlist'
            },
            comingNext: programNumber + 1 !== channels.length ? {
              program: channels[programNumber + 1]?.title ?? channels[programNumber + 1].config.program,
              title: channels[programNumber + 1]?.preTitle ?? channels[programNumber + 1].config.title
            } : {}
          };
          break;
        default:
          break;
      }

      player.userEvents$.next({
        action: USER_REQUEST_MEDIA_LOAD,
        value: {
          src,
          config: {
            ...config,
            name,
            intro: false,
            autostart: true,
            ...tracking
          }
        },
        source
      });
    }
  }, [metadata.id, activeProgramMetadata?.name]);

  return (
    <div
      style={{
        ...setStyles(ZAP_PROGRAM_WRAPPER),
        ...active && ({ background: ACTIVE_ITEM_BACKGROUND })
      }}
      role="button"
      onKeyPress={() => handleClick(true)}
      onClick={handleClick}
      tabIndex={0}
      className="ftv-magneto-pointer"
    >
      <div style={setStyles(ZAP_PROGRAM_PREVIEW)}>
        <div style={{ ...PROGRAM_VISUAL_STYLE }}>
          {metadata.logo && (
          <img
            width={small ? 30 : 44}
            height={small ? 30 : 44}
            src={metadata.logo}
            alt={metadata.name}
            draggable={false}
            onDragStart={(e) => e.preventDefault()}
          />
          )}
        </div>
      </div>
      <div style={setStyles(ZAP_PROGRAM_TITLES)}>
        <div style={setStyles(ZAP_PROGRAM_MAIN_TITLE)}>{metadata.title}</div>
        <div style={setStyles(ZAP_PROGRAM_SECOND_TITLE)}>
          {metadata.preTitle ? `${metadata.preTitle} ` : '' }
          {metadata.additionalTitle}
        </div>
      </div>
    </div>
  );
}

export default withBreakPoints(Program);
